<template>
    <div class="ui-card">
        <div v-if="hasHeader" class="ui-card-header" :style="headerStyle">
            <div v-if="title || $slots.title" class="title"><slot name="title">{{title}}</slot></div>
            <div v-if="subtitle || $slots.subtitle" class="subtitle"><slot name="subtitle">{{subtitle}}</slot></div>
        </div>
        <div class="ui-card-body" :style="bodyStyle">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiCard',
    props: {
        title: {
            type: String,
        },
        subtitle: {
            type: String,
        },
        headerStyle: {
            default: () => ({}),
        },
        bodyStyle: {
            default: () => ({}),
        },
    },
    computed: {
        hasHeader: {
            get() {
                return !!(this.title || this.subtitle || this.$slots.title || this.$slots.subtitle)
            },
            cache: false,
        },
    },
}
</script>

<style lang="scss">
.ui-card {
    .ui-card-header {
        padding-top: 30px;
        .title {
            overflow: hidden;
            margin: 0;
            line-height: 1.6em;
            font-size: 28px;
            font-weight: 600;
            color: #000000;
        }
        .subtitle {
            font-size: 1.8rem;
            color: #979797;
            line-height: 1.3em;
        }
    }
    .ui-card-body {
        padding: 15px 0;
        color: #000;
        font-size: 2rem;
        line-height: 1.4em;
        p {
            white-space: pre-line;
            &:last-child {
                margin-bottom: 0;
            }
        }
        textarea {
            padding: 1em;
            width: 100%;
            font-size: 2rem;
            line-height: 1.5;
            height: 7rem;
            border: 1px solid #979797;
            border-radius: 5px;
            min-height: 14rem;
        }
    }
}
</style>