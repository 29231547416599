var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qna-detail container" },
    [
      !_vm.noData
        ? [
            _c(
              "card",
              {
                attrs: {
                  title: "의뢰 정보",
                  "header-style": { paddingTop: 0 },
                  "body-style": { paddingBottom: 0 }
                }
              },
              [
                [
                  _c("div", { staticClass: "ui-border-line" }),
                  _c("label-button", {
                    attrs: {
                      title:
                        _vm.$lib.t_genre(_vm.genre) +
                        " | " +
                        _vm.eventType +
                        " " +
                        _vm.playDate,
                      titleColor: "#5a5a5a",
                      "right-icon": "next"
                    },
                    on: {
                      click: function() {
                        return _vm.$gotoWeb("/offer/" + _vm.offerUid)
                      }
                    }
                  })
                ],
                [
                  _c("div", { staticClass: "ui-border-line" }),
                  _c("label-button", {
                    attrs: {
                      title: _vm.isClient ? "견적서 보기" : "견적서 보기/수정",
                      titleColor: "#5a5a5a",
                      "right-icon": "next"
                    },
                    on: {
                      click: function() {
                        var url =
                          "/" +
                          _vm.userData.category +
                          "/my-offer/" +
                          _vm.offerUid +
                          "/" +
                          _vm.artistidx

                        if (!_vm.isClient) {
                          url += "?ableChangePrice=true"
                        }

                        _vm.$gotoWeb(url)
                      }
                    }
                  })
                ]
              ],
              2
            ),
            _c("div", {
              staticClass: "ui-border-line",
              staticStyle: { height: "10px" }
            }),
            _c(
              "card",
              { attrs: { title: "문의 내용" } },
              [
                _vm.qnaData.detail
                  ? _c("UrlInText", { attrs: { text: _vm.qnaData.detail } })
                  : _vm._e()
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line",
              staticStyle: { height: "10px" }
            }),
            _vm.isClient
              ? [
                  _c("card", [
                    _c(
                      "p",
                      {
                        staticClass: "m-0 ui-weight-5",
                        style: { color: "#f33d12", fontSize: "2rem" }
                      },
                      [_vm._v("답변 확인 후 예약은 어떻게 하나요?")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "m-0 ui-p-indent-2",
                        style: {
                          fontSize: "1.9rem",
                          paddingTop: "0.6rem",
                          color: "#969696"
                        }
                      },
                      [_vm._v("1. '예약하기'로 예약을 확정하세요.")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "m-0 ui-p-indent-2",
                        style: { fontSize: "1.9rem", color: "#969696" }
                      },
                      [
                        _vm._v(
                          "2. 결제 후에는 '결제내역>채팅하기'로 소통하세요."
                        )
                      ]
                    )
                  ]),
                  _c("div", {
                    staticClass: "ui-border-line",
                    staticStyle: { height: "10px" }
                  })
                ]
              : [
                  _c("card", [
                    _c(
                      "p",
                      {
                        staticClass: "m-0 ui-weight-5",
                        style: { color: "#f33d12", fontSize: "2rem" }
                      },
                      [_vm._v("진행 순서 알려드릴게요.")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "m-0 ui-p-indent-2",
                        style: {
                          fontSize: "1.9rem",
                          paddingTop: "0.6rem",
                          color: "#969696"
                        }
                      },
                      [_vm._v("1. 예약하기(결제)를 요청하세요.")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "m-0 ui-p-indent-2",
                        style: { fontSize: "1.9rem", color: "#969696" }
                      },
                      [
                        _vm._v(
                          "2. 결제 후에는 채팅하기로 소통하세요.(MY>결제내역)"
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "m-0 ui-p-indent-2",
                        style: { fontSize: "1.9rem", color: "#969696" }
                      },
                      [_vm._v("3. 행사가 종료되면 리뷰를 요청하세요.")]
                    )
                  ]),
                  _c("div", {
                    staticClass: "ui-border-line",
                    staticStyle: { height: "10px" }
                  })
                ],
            _vm.isClient && !_vm.hasReply
              ? [
                  _c("blank-search", {
                    attrs: {
                      text: "조금만 기다려주세요. 곧 답변이 도착할거예요"
                    }
                  })
                ]
              : _c(
                  "card",
                  [
                    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v("답변 "),
                      _vm.isClient
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "ui-btn text-underline color-lavender ui-weight-5 float-right",
                              staticStyle: { "font-size": "18px" },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.ReportModal.show()
                                }
                              }
                            },
                            [_vm._v("신고하기")]
                          )
                        : _vm._e()
                    ]),
                    _vm.isArtist
                      ? _c(
                          "div",
                          { attrs: { slot: "subtitle" }, slot: "subtitle" },
                          [
                            _vm._v(
                              " 개인 거래, 결제 전 연락처(SNS) 교환 등은 불가합니다."
                            ),
                            _c("br"),
                            _vm._v(
                              " 불가피한 상황이 있거나 도움이 필요하다면?" +
                                _vm._s(" ") +
                                " "
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "ui-btn text-underline color-lavender",
                                on: {
                                  click: function($event) {
                                    return _vm.$gotoWeb(
                                      "http://pf.kakao.com/_yzxhwC/chat"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 도움 요청 ")]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.readonly
                      ? [
                          _vm.qnaData.reply
                            ? _c("UrlInText", {
                                attrs: { text: _vm.qnaData.reply }
                              })
                            : _vm._e()
                        ]
                      : [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.reply,
                                expression: "formData.reply"
                              }
                            ],
                            domProps: { value: _vm.formData.reply },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formData,
                                  "reply",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                  ],
                  2
                ),
            _vm.isClient
              ? [
                  _vm.isPaidOffer
                    ? _c("footer-box", {
                        attrs: {
                          "submit-text": _vm.isNewMembershipUser
                            ? "채팅하기"
                            : "문의하기",
                          "submit-cb": function() {
                            return _vm.isNewMembershipUser
                              ? _vm.createMsgRoom()
                              : _vm.gotoContactToArtist()
                          }
                        }
                      })
                    : _c("footer-box", {
                        attrs: {
                          single: false,
                          "btn-text": _vm.isNewMembershipUser
                            ? "채팅하기"
                            : "문의하기",
                          "btn-cb": function() {
                            return _vm.isNewMembershipUser
                              ? _vm.createMsgRoom()
                              : _vm.gotoContactToArtist()
                          },
                          "submit-text": "예약하기 >",
                          "submit-cb": function() {
                            return _vm.gotoPayment()
                          }
                        }
                      })
                ]
              : _vm.isArtist && !_vm.hasReply
              ? [
                  _c("footer-box", {
                    attrs: {
                      "submit-text": "답변 보내기",
                      "submit-cb": function() {
                        if (_vm.confirmModal.ok) {
                          _vm.confirmModal.ok = false
                          _vm.confirmModal.show = false
                          _vm.postAnswer()
                          return
                        }
                        _vm.confirmModal.ok = true
                        _vm.confirmModal.show = true
                      },
                      "submit-disabled": !_vm.formData.reply,
                      modalShow: _vm.confirmModal.show,
                      modalTitle: "답변 내용 확인하셨나요?",
                      modalBody:
                        "보낸 답변은 수정이나 삭제가 불가해요.\n보내려면 한번 더 터치!",
                      modalCb: function() {
                        _vm.confirmModal.ok = true
                        _vm.confirmModal.show = false
                      }
                    }
                  })
                ]
              : _vm._e()
          ]
        : [_c("blank-search", { attrs: { text: "잘못된 접근입니다." } })],
      _c(
        "modal",
        {
          ref: "ReportModal",
          attrs: {
            title: "신고하기",
            confirm: "신고하기",
            confirmCb: function(modal) {
              _vm.reportData.title = _vm.reportModalData.title
              _vm.reportData.contents = _vm.reportModalData.contents
              _vm.postReport()
              modal.hide()
            },
            showCb: function() {
              _vm.reportModalData.title = _vm.reportData.title
              _vm.reportModalData.contents = _vm.reportData.contents
            }
          }
        },
        [
          _c(
            "ul",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _vm._l(_vm.reportText, function(row, key) {
                return _c(
                  "li",
                  {
                    key: key,
                    staticClass: "text-left",
                    class: { on: _vm.reportModalData.title === row },
                    on: {
                      click: function() {
                        _vm.reportModalData.title = row
                        if (_vm.reportModalData.title !== "기타") {
                          _vm.reportModalData.contents = ""
                        }
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(row) + " "),
                    _c("icon", {
                      staticClass: "icon",
                      attrs: {
                        src:
                          "img/common/check" +
                          (_vm.reportModalData.title === row ? "-on" : "") +
                          ".png",
                        width: "2rem",
                        height: "8rem",
                        cover: false
                      }
                    }),
                    _c("div", { staticClass: "ui-border-line" })
                  ],
                  1
                )
              }),
              _vm.reportModalData.title === "기타"
                ? _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reportModalData.contents,
                        expression: "reportModalData.contents"
                      }
                    ],
                    staticClass: "report-etcTitle",
                    attrs: {
                      placeholder: "사유를 적어주세요 (30자)",
                      maxlength: "30",
                      disabled: _vm.reportModalData.title !== "기타"
                    },
                    domProps: { value: _vm.reportModalData.contents },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.reportModalData,
                          "contents",
                          $event.target.value
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }