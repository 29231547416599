<template>
    <div class="qna-detail container">
        <template v-if="!noData">
            <card title="의뢰 정보" :header-style="{ paddingTop: 0 }" :body-style="{ paddingBottom: 0 }">
                <template>
                    <div class="ui-border-line"></div>
                    <label-button
                        :title="`${$lib.t_genre(genre)} | ${eventType} ${playDate}`"
                        titleColor="#5a5a5a"
                        right-icon="next"
                        @click="() => $gotoWeb(`/offer/${offerUid}`)"
                    ></label-button>
                </template>

                <template>
                    <div class="ui-border-line"></div>
                    <label-button
                        :title="isClient ? '견적서 보기' : '견적서 보기/수정'"
                        titleColor="#5a5a5a"
                        right-icon="next"
                        @click="() => {
                            let url = `/${userData.category}/my-offer/${offerUid}/${artistidx}`

                            if (!isClient) {
                                url += '?ableChangePrice=true'
                            }

                            $gotoWeb(url)
                        }"
                    ></label-button>
                </template>
            </card>

            <div class="ui-border-line" style="height: 10px"></div>

            <card title="문의 내용">
                <UrlInText v-if="qnaData.detail" :text="qnaData.detail" />
            </card>

            <div class="ui-border-line" style="height: 10px"></div>

            <template v-if="isClient">
                <card>
                    <p class="m-0 ui-weight-5" :style="{ color: '#f33d12', fontSize: '2rem' }">답변 확인 후 예약은 어떻게 하나요?</p>
                    <p class="m-0 ui-p-indent-2" :style="{ fontSize: '1.9rem', paddingTop: '0.6rem', color: '#969696' }">1. '예약하기'로 예약을 확정하세요.</p>
                    <p class="m-0 ui-p-indent-2" :style="{ fontSize: '1.9rem', color: '#969696'  }">2. 결제 후에는 '결제내역>채팅하기'로 소통하세요.</p>
                </card>
                <div class="ui-border-line" style="height: 10px"></div>
            </template>
            <template v-else>
                <card>
                    <p class="m-0 ui-weight-5" :style="{ color: '#f33d12', fontSize: '2rem' }">진행 순서 알려드릴게요.</p>
                    <p class="m-0 ui-p-indent-2" :style="{ fontSize: '1.9rem', paddingTop: '0.6rem', color: '#969696'   }">1. 예약하기(결제)를 요청하세요.</p>
                    <p class="m-0 ui-p-indent-2" :style="{ fontSize: '1.9rem', color: '#969696'  }">2. 결제 후에는 채팅하기로 소통하세요.(MY>결제내역)</p>
                    <p class="m-0 ui-p-indent-2" :style="{ fontSize: '1.9rem', color: '#969696'  }">3. 행사가 종료되면 리뷰를 요청하세요.</p>
                </card>
                <div class="ui-border-line" style="height: 10px"></div>
            </template>

            <!-- <template v-if="isArtist && !isNewMembershipUser && isApprovedProfile">
                <div
                    :style="{
                        margin: '0 -30px',
                    }"
                >
                    <img
                        :src="$lib.imgUrl('img/qna/memnership-banner.png')"
                        width="100%"
                        @click="$router.push('/new-membership')"
                    />
                </div>

                <div class="ui-border-line" style="height: 10px"></div>
            </template> -->

            <template v-if="isClient && !hasReply">
                <blank-search text="조금만 기다려주세요. 곧 답변이 도착할거예요" />
            </template>
            <card v-else>
                    <div slot="title">답변 <button v-if="isClient" class="ui-btn text-underline color-lavender ui-weight-5 float-right" style="font-size: 18px" @click="$refs.ReportModal.show()">신고하기</button></div>
                    <div v-if="isArtist" slot="subtitle">
                        개인 거래, 결제 전 연락처(SNS) 교환 등은 불가합니다.<br>
                        불가피한 상황이 있거나 도움이 필요하다면?{{' '}}
                        <button
                            class="ui-btn text-underline color-lavender"
                            @click="$gotoWeb('http://pf.kakao.com/_yzxhwC/chat')"
                        >
                            도움 요청
                        </button>
                    </div>
                    <!-- TODO: 일단 보류 -->
                    <!-- <div v-if="isArtist" slot="subtitle">
                        안전결제 미사용, 결제 전 연락처 공개 시 이용 정지됩니다.<br>
                        헤이비글의 도움이 필요하다면?{{' '}}
                        <button
                            class="ui-btn text-underline color-lavender"
                            @click="$gotoWeb('http://pf.kakao.com/_yzxhwC/chat')"
                        >
                            도움 요청
                        </button>
                    </div> -->
                    <template v-if="readonly">
                        <UrlInText v-if="qnaData.reply" :text="qnaData.reply" />
                    </template>
                    <template v-else>
                        <textarea v-model="formData.reply" />
                    </template>
            </card>

            <template v-if="isClient">
                <footer-box
                    v-if="isPaidOffer"
                    :submit-text="isNewMembershipUser ? '채팅하기' : '문의하기'"
                    :submit-cb="() => isNewMembershipUser ? createMsgRoom() : gotoContactToArtist()"
                />
                <footer-box
                    v-else
                    :single="false"
                    :btn-text="isNewMembershipUser ? '채팅하기' : '문의하기'"
                    :btn-cb="() => isNewMembershipUser ? createMsgRoom() : gotoContactToArtist()"
                    submit-text="예약하기 >"
                    :submit-cb="() => gotoPayment()"
                />
            </template>
            <template v-else-if="isArtist && !hasReply">
                <footer-box
                    submit-text="답변 보내기"
                    :submit-cb="() => {
                        if (confirmModal.ok) {
                            confirmModal.ok = false
                            confirmModal.show = false
                            postAnswer()
                            return
                        }
                        confirmModal.ok = true
                        confirmModal.show = true
                    }"
                    :submit-disabled="!formData.reply"
                    :modalShow="confirmModal.show"
                    :modalTitle="'답변 내용 확인하셨나요?'"
                    :modalBody="`보낸 답변은 수정이나 삭제가 불가해요.\n보내려면 한번 더 터치!`"
                    :modalCb="() => {
                        confirmModal.ok = true
                        confirmModal.show = false
                    }"
                />
            </template>
        </template>
        <template v-else>
            <blank-search text="잘못된 접근입니다." />
        </template>

        <modal
            ref="ReportModal"
            title="신고하기"
            confirm="신고하기"
            :confirmCb="modal => {
                reportData.title = reportModalData.title
                reportData.contents = reportModalData.contents
                postReport()
                modal.hide()
            }"
            :showCb="() => {
                reportModalData.title = reportData.title
                reportModalData.contents = reportData.contents
            }"
            >
            <ul slot="body">
                <li
                    class="text-left"
                    v-for="(row, key) in reportText" :key="key"
                    @click="() =>{
                        reportModalData.title = row
                        if (reportModalData.title !== '기타') reportModalData.contents = ''
                    }"
                    :class="{ on: reportModalData.title === row }"
                >
                    {{row}}
                    <icon class="icon" :src="`img/common/check${reportModalData.title === row ? '-on' : ''}.png`" width="2rem" height="8rem" :cover="false" />
                    <div class="ui-border-line"></div>
                </li>
                <textarea
                    v-if="reportModalData.title === '기타'"
                    class="report-etcTitle" placeholder="사유를 적어주세요 (30자)" maxlength="30" v-model="reportModalData.contents" :disabled="reportModalData.title !== '기타'"></textarea>
            </ul>
        </modal>
    </div>
</template>

<script>
import LabelButton from '@/components/common/LabelButton'
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import BlankSearch from '@/components/blank/BlankList'
import Card from '@/components/common/Card'
import Modal from '@/components/common/Modal'
import UrlInText from '@/components/common/UrlInText'

/**
 * QnaDetail
 *
 * - keep-alive
 */
export default {
    name: 'QnaDetail',
    components: {
        LabelButton,
        Icon,
        FooterBox,
        BlankSearch,
        Card,
        Modal,
        UrlInText,
    },
    data() {
        return {
            qnaData: {
                uid: null,
                genre: '',
                eventType: '',
                playDate: '',
                detail: '',
                reply: '',
                replyDate: '',
                artistidx: null,
                offerUid: null,
            },
            formData: {
                reply: '',
            },
            offerData: {},
            artistData: {},
            events: {
                root: {},
            },
            reportData: {
                title: '',
                contents: '',
            },
            reportModalData: {
                title: '',
                contents: '',
            },
            reportText: [
                '직거래 및 이체 요구',
                '허위사실 등록',
                '견적서와 다른 추가금 요구',
                '기타',
            ],
            confirmModal: {
                show: false,
                ok: false,
            },
        }
    },
    computed: {
        noData() {
            return !(this.qnaData.uid && this.eventType && this.playDate && this.genre)
        },
        userData() {
            return this.$store?.state?.user?.userData
        },
        isArtist() {
            return this.userData?.category === 'artist'
        },
        isClient() {
            return this.userData?.category === 'client'
        },
        offerUid() {
            return this.qnaData?.offerUid
        },
        artistidx() {
            return this.qnaData.artistidx || this.offerData.artistidx
        },
        genre() {
            return this.qnaData.genre || this.offerData.genre || ''
        },
        eventType() {
            return this.qnaData.eventType || this.offerData.eventType || ''
        },
        playDate() {
            return (
                this.qnaData.playDate || (this.offerData.playDate > 0 && moment(this.offerData.playDate * 1000).format('YYYY.MM.DD')) || ''
            )
        },
        hasReply() {
            return this?.qnaData?.replyDate > 0
        },
        readonly() {
            return this.isClient || this.hasReply
        },
        isPaidOffer() {
            const condition = {
                default: [
                    () => ['ing', 'selfEnd', 'autoEnd', 'cancel'].indexOf(this.offerData?.nowState) === -1,
                    () => this.offerData?.payStatus === '00',
                ],
                artist: [
                    () => this.userData?.artistidx == this.offerData?.artistidx,
                ],
                client: [
                    () => this.userData?.useridx == this.offerData?.useridx,
                    // () => this.artistidx === this.offerData?.artistidx,
                ],
            }
            if (condition.default.every(fn => fn())) {
                return condition[this.isArtist ? 'artist' : 'client'].every(fn => fn())
            }

            return false
        },
        isNewMembershipUser() {
            if (this.isArtist) {
                return this.$store.getters.isNewMembershipUser
            }

            return this.$lib.isNewMembershipUser(this.artistData?.newMemberDate)
        },
        isApprovedProfile() {
            return this.$store.getters.isApprovedProfile
        },
    },
    watch: {
        offerUid(offerUid) {
            offerUid > 0 && this.getOffer()
        },
        artistidx(artistidx) {
            artistidx > 0 && this.getArtist()
        },
    },
    created() {
        this.events.root['qna:update'] = params => {
            this.reset()
            this.$nextTick(() => {
                if (params && typeof params === 'object') {
                    this.qnaData = { ...params }
                }
            })
        }

        for (const key in this.events.root) {
            if (this.events.root.hasOwnProperty(key)) {
                const cb = this.events.root[key]
                this.$root.$on(key, cb)
            }
        }
    },
    beforeDestroy() {
        for (const key in this.events.root) {
            if (this.events.root.hasOwnProperty(key)) {
                const cb = this.events.root[key]
                this.$root.$off(key, cb)
            }
        }
    },
    beforeRouteLeave (to, from, next) {
        next()
    },
    methods: {
        getOffer() {
            if (!this.offerUid) return

            const req = {
                method: 'get',
                url: `/client/offer/${this.offerUid}`,
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        let offerData = data?.response?.offerData || {}

                        if (!offerData.offerUid) {
                            this.$router.back()
                            return
                        }

                        this.offerData = offerData
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
        getArtist() {
            if (!this.artistidx) return

            const req = {
                method: 'get',
                url: `/artist/profile/${this.artistidx}`,
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        let artistData = data?.response?.artistData || {}

                        if (!artistData.artistidx) {
                            this.$router.back()
                            return
                        }

                        this.artistData = artistData
                    }
                })
                .catch(log.error)
        },
        postAnswer() {
            if (!this.qnaData.uid || !this.formData.reply) return

            const req = {
                method: 'post',
                url: `/artist/offer/${this.qnaData.uid}/contact`,
                data: {
                    reply: this.formData.reply,
                },
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (this.$lib.validNgWordCount(data, this.$store) === false) {
                        return
                    }

                    if (resultData.success) {
                        this.$root.$emit('qna:reply', this.qnaData.uid, this.formData.reply)
                        this.$toast('답변을 전달하였습니다.').back()
                        this.formData.reply = ''
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
        gotoContactToArtist() {
            this.$router.push({
                path: `/etc/contact-to-artist/${this.offerUid}`,
                query: {
                    artistidx: this.artistData.artistidx,
                    teamName: this.artistData.teamName,
                    teamImgUrl: this.artistData.teamImgUrl,
                },
            })
        },
        postReport() {
            if (!this.reportData.title) return

            const reportUseridx = this.artistData.artistidx
            const req = {
                method: 'post',
                url: `/user/report/${reportUseridx}`,
                data: {
                title: this.reportData.title,
                contents: this.reportData.contents,
                }
            }

            return this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        this.$toast('신고가 처리되었습니다.')
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
        gotoPayment() {
            if (this.offerUid && this.artistidx) {
                this.$router.push(`/payment/${this.offerUid}/${this.artistidx}`)
            } else {
                this.$toast('잘못된 요청입니다.')
            }
        },
        /**
         * 초기화
         */
        reset() {
            this.qnaData.uid = null
            this.qnaData.genre = ''
            this.qnaData.eventType = ''
            this.qnaData.playDate = ''
            this.qnaData.detail = ''
            this.qnaData.reply = ''
            this.qnaData.replyDate = ''
            this.qnaData.artistidx = null
            this.qnaData.offerUid = null

            this.confirmModal.ok = false
            this.confirmModal.show = false
        },
        createMsgRoom() {
            const req = {
                method: 'post',
                url: `/chat/${this.offerData.offerUid}`,
                data: {
                    useridx: this.offerData.useridx,
                    targetUseridx: this.artistData.artistidx,
                    targetTeamName: this.artistData.teamName,
                }
            }

            this.$http(req)
                .then(({ data }) => {
                log(...Object.values(req), data)

                const resultData = this.$lib.resultCheck(data.resultData)

                if (resultData.success) {
                    this.$router.push({
                        path: `/message/${this.offerData.offerUid}/${this.artistData.artistidx}`,
                        query: {
                            allowBack: 'true',
                        },
                    })
                } else {
                    this.$alert(resultData)
                }
                })
                .catch(log.error)
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.report-etcTitle {
    width: 100%;
    height: 80px;
    color: #000;
    border: 0;
    margin: 0;
    padding: 2rem 1rem;
    font-size: 1.8rem;
    resize: none;
    textarea {
      padding: 0;
      height: 124px;
      font-size: 18px !important;
      line-height: 1.5;
    }
}
</style>
