var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-card" }, [
    _vm.hasHeader
      ? _c("div", { staticClass: "ui-card-header", style: _vm.headerStyle }, [
          _vm.title || _vm.$slots.title
            ? _c(
                "div",
                { staticClass: "title" },
                [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                2
              )
            : _vm._e(),
          _vm.subtitle || _vm.$slots.subtitle
            ? _c(
                "div",
                { staticClass: "subtitle" },
                [_vm._t("subtitle", [_vm._v(_vm._s(_vm.subtitle))])],
                2
              )
            : _vm._e()
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "ui-card-body", style: _vm.bodyStyle },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }